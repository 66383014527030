import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/login.vue')
  },
  {
    path: '/',
    name: 'home',
    redirect: "/Homepage",
    component: () => import('../views/home.vue'),
    children: [
      {
        path: '/Homepage',
        name: 'Homepage',
        component: () => import('@/views/Homepage/Homepage.vue'),
      },
      {
        path: '/WaitlistList',
        name: 'WaitlistList',
        component: () => import('@/views/Homepage/WaitlistList.vue'),
      },
      {
        path: '/CheckupInformation',
        name: 'CheckupInformation',
        component: () => import('@/views/Homepage/CheckupInformation.vue'),
      },
      {
        path: '/WaitlistPending',
        name: 'WaitlistPending',
        component: () => import('@/views/Homepage/WaitlistPending.vue'),
      },
      {
        path: '/HistoryList',
        name: 'HistoryList',
        component: () => import('@/views/Homepage/HistoryList.vue'),
      },
      {
        path: '/HistoryDetail',
        name: 'HistoryDetail',
        component: () => import('@/views/Homepage/HistoryDetail.vue'),
      },
      {
        path: '/DiagnosisDetails',
        name: 'DiagnosisDetails',
        component: () => import('@/views/Homepage/DiagnosisDetails.vue'),
      },
      {
        path: '/MonthlyReport',
        name: 'MonthlyReport',
        component: () => import('@/views/Homepage/MonthlyReport.vue'),
      },
      {
        path: '/MonthlyReportLog',
        name: 'MonthlyReportLog',
        component: () => import('@/views/Homepage/MonthlyReportLog.vue'),
      },
      {
        path: '/MonthlyReportDetail',
        name: 'MonthlyReportDetail',
        component: () => import('@/views/Homepage/MonthlyReportDetail.vue'),
      },
    ]
  }
]

const router = new VueRouter({
  routes
})

export default router
